<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/cards/6-1400.jpg')" title="COMMERCIAL PILOT LICENCE" subtitle="Fly for a living" />
    <div class="program-description">
      <h1>Requirements</h1>
      <div class="list-container">
        <ul class="program-list">
          <li>Minimum Age: 18 years old</li>
          <li>Aeronautical Experience: Minimum 200 hrs</li>
          <li>Pilot in Command Flight Time: Minimum 70-100 hrs</li>
          <li>Pilot in Command Cross-country: Minimum 20 hrs</li>
          <li>Dual instrument time: Minimum 10 hrs</li>
          <li>Dual instrument fight time: Minimum 5 hrs</li>
          <li>Medical: Class 1</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
